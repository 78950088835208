import ArticleCard from "components/articles/ArticleCard"

import MainCategory from "components/articles/MainCategory"
//import SubCategory from "components/articles/SubCategory"
import React from "react"
import Masonry from "react-masonry-css"
import { Link, RouteComponentProps } from "react-router-dom"
import { Route, Switch } from "react-router-dom"
import { fetchArticles } from "service/article.http"
import {
  IArticleAuthor,
  IArticle,
  IArticleCategory,
} from "service/article.types"
import ModulePage from "./ModulePage"
import "pages/styles/panelpage.css"
import Progress from "components/articles/Progress"
import Translate from "service/Translate"
import { IContext, LoginContext } from "Context/LoginContext"
import ExercisePage from "./ExercisePage"
import ReflectionsPage from "./ReflectionsPage"
import { exercies } from "components/exercises/Exercises"
import FlagGroup from "components/FlagGroup"
import LinkToAppButton from "components/LinkToAppButton"
import PanelPageLogoName from "components/PanelPageLogoName"
//import HamburgerMenu from "components/HamburgerMenu"

const tn: Translate = Translate.getInstance()

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 2,
}

interface IProps extends RouteComponentProps {}

interface IState {
  categories: IArticleCategory[]
  filteredCategories: IArticleCategory[]
  authors: Map<number, IArticleAuthor>
  articles: IArticle[]
  filteredArticles: IArticle[]
  visibleArticlesCount: number
  // readArticles: number[];
  isShowingAll: boolean
  selectedCategory: number
  selectedSubCategory: number
  me: number
  expandedCategory: number | null
  coparenting: number
  children: number
  isMenuOpen: boolean
  showFixedHeader: boolean
  hasForwarded: boolean
}

class PanelPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      categories: [],
      isMenuOpen: false,
      filteredCategories: [],
      authors: new Map<number, IArticleAuthor>(),
      articles: [],
      filteredArticles: [],
      visibleArticlesCount: 6,
      isShowingAll: false,
      // readArticles: [],
      expandedCategory: null,
      selectedCategory: -1,
      selectedSubCategory: -1,
      me: 0,
      coparenting: 0,
      children: 0,
      showFixedHeader: false,
      hasForwarded: false,
    }
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      isShowingAll: !prevState.isShowingAll,
    }))
  }

  toggleCategory = (categoryId: number) => {
    this.setState((prevState) => ({
      expandedCategory:
        prevState.expandedCategory === categoryId ? null : categoryId,
    }))
  }
  public componentDidMount(): void {
    const errorCallback = (error: string) => console.log(error)
    const categoryCallback = (data: IArticleCategory[]) => {
      this.setState({ categories: data, filteredCategories: data })
      this.selectCategory(-1, -1)
    }
    const authorCallback = (data: Map<number, IArticleAuthor>) =>
      this.setState({ authors: data })
    const articleCallback = (data: IArticle[]) => {
      this.setState({
        articles: data.concat(this.state.articles),
        filteredArticles: data.concat(this.state.articles),
      })
      this.selectCategory(-1, -1)
    }

    fetchArticles(
      errorCallback,
      categoryCallback,
      authorCallback,
      articleCallback
    )
  }

  public componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (this.state.articles !== prevState.articles) {
      this.reCalculate()
    }
    const context: IContext = this.context
    if (
      this.state.hasForwarded === false &&
      context.initiated &&
      context.readArticles.length === 0
    ) {
      const { history } = this.props
      this.setState({ hasForwarded: true })
      setTimeout(() => {
        history.push(
          tn.getLanguage() === "SE"
            ? "/panel/article/sa-funkar-det"
            : "/panel/article/get-started-in-varannan-vecka"
        )
      }, 100)
    }
  }

  private selectCategory(
    mainCat: number,
    subCat: number,
    newLang?: "SE" | "EN"
  ) {
    let newMainCat: number = mainCat ? mainCat : this.state.selectedCategory
    let newSubCat = subCat === this.state.selectedSubCategory ? -1 : subCat
    const lang = newLang ? newLang : tn.getLanguage()

    if (newMainCat === this.state.selectedCategory && subCat === undefined) {
      newMainCat = -1
    }

    if (mainCat !== this.state.selectedCategory) {
      newSubCat = -1
    }

    let articles = this.state.articles.filter(
      (article: IArticle) =>
        ((newMainCat === -1 &&
          (article.pointsMe > 0 ||
            article.pointsCoparenting > 0 ||
            article.pointsChild > 0)) ||
          (newMainCat === 1 && article.pointsMe > 0) ||
          (newMainCat === 2 && article.pointsCoparenting > 0) ||
          (newMainCat === 3 && article.pointsChild > 0)) &&
        article.lang === lang
    )

    const subCategories: number[] = []
    for (const article of articles) {
      for (const articleCat of article.categories) {
        if (subCategories.indexOf(articleCat) === -1) {
          subCategories.push(articleCat)
        }
      }
    }

    const filteredCategories: IArticleCategory[] = []
    for (const cat of subCategories) {
      const foundCat = this.state.categories.find((cat2) => cat2.id === cat)
      if (foundCat) {
        filteredCategories.push(foundCat)
      }
    }

    filteredCategories.sort(
      (catA, catB) =>
        Number.parseInt(catA.order, 10) - Number.parseInt(catB.order, 10)
    )

    if (newSubCat > -1) {
      articles = articles.filter(
        (article: IArticle) => article.categories.indexOf(newSubCat) > -1
      )
    }
    if (newMainCat === -1) {
      articles.sort(
        (artA, artB) =>
          artB.pointsMe +
          artB.pointsCoparenting +
          artB.pointsChild -
          (artA.pointsMe + artA.pointsCoparenting + artA.pointsChild)
      )
    } else {
      articles.sort(
        (artA, artB) =>
          (newMainCat === 1
            ? artB.pointsMe
            : newMainCat === 2
            ? artB.pointsCoparenting
            : artB.pointsChild) -
          (newMainCat === 1
            ? artA.pointsMe
            : newMainCat === 2
            ? artA.pointsCoparenting
            : artA.pointsChild)
      )
    }

    this.setState({
      filteredArticles: articles,
      filteredCategories,
      selectedCategory: newMainCat,
      selectedSubCategory: newSubCat,
    })
    if (newLang) {
      const context: IContext = this.context
      context.setLang(newLang)
    }
  }

  public render() {
    const { filteredArticles, isShowingAll } = this.state
    const articlesToShow = isShowingAll
      ? filteredArticles
      : filteredArticles.slice(0, 6)
    const context: IContext = this.context

    const loading =
      this.state.articles.length === 0 ||
      this.state.authors.size === 0 ||
      this.state.categories.length === 0
        ? false
        : true

    return (
      <>
        <div
          className="loading"
          style={loading ? { opacity: 0, pointerEvents: "none" } : {}}
        >
          <img
            alt="loading"
            style={{ width: "auto", height: "300px" }}
            src={require("../images/loading.gif")}
          />
        </div>
        <div className="backdrop" />
        <Switch>
          <Route path="/panel/article/:slug">
            <ModulePage
              readArticles={context.readArticles}
              articleRead={(slug: string) =>
                this.articleRead(
                  this.state.articles.find((art) => art.slug === slug)?.id
                )
              }
              reCalculate={() => this.reCalculate()}
              articles={this.state.articles}
            />
          </Route>
          <Route path="/panel/exercise/:slug">
            <ExercisePage
              exerciseDone={(slug: string, response: string) =>
                this.exerciseCompleted(slug, response)
              }
              reCalculate={() => this.reCalculate()}
            />
          </Route>
          <Route path="/panel/reflections">
            <ReflectionsPage reflections={context.completedExercises} />
          </Route>
        </Switch>
        <div className="muni-page" onScroll={(e) => this.onScroll(e)}>
          <div
            className="muni-toppanel"
            style={
              this.state.showFixedHeader
                ? { opacity: 1, pointerEvents: "none" }
                : {}
            }
          >
            <div className="muni-flagGroup-panelPage-desktop">
              <FlagGroup />
            </div>
            {/* 
   <div className="muni-flagGroup-panelPage-mobile">
              {!this.state.isMenuOpen && (
                <div className="hamburger-icon" onClick={this.toggleMenu}>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
                  <span className="hamburger-line"></span>
                </div>
              )}

              <HamburgerMenu
                isOpen={this.state.isMenuOpen}
                onClose={this.toggleMenu}
                showLoginButton={false}
              />
            </div>

*/}

            <PanelPageLogoName />

            <div className="muni-topgroup muni-topgroup-activity">
              <div className="muni-grouptitle">{tn.lang.activity}</div>
              <ul style={{ fontStyle: "italic" }}>
                {context.readArticles.slice(0, 5).map((articleId: number) => {
                  const article = this.state.articles.find(
                    (article) => article.id === articleId
                  )
                  return (
                    <li key={articleId}>
                      <span className="muni-activityaction">
                        {tn.lang.read + ": "}
                      </span>
                      <span
                        className="muni-activitytarget"
                        dangerouslySetInnerHTML={{
                          __html: article ? article.title : "",
                        }}
                      />
                    </li>
                  )
                })}
              </ul>

              <div className="muni-linkToAppButton">
                <LinkToAppButton />
              </div>
            </div>

            <div className="muni-topgroup muni-topgroup-minwidth">
              <div className="muni-grouptitle">{tn.lang.completedcat}</div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Progress
                  progress={this.state.me}
                  label={tn.lang.aboutMe}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 1 ? -1 : 1,
                      -1
                    )
                  }
                />

                <Progress
                  progress={this.state.coparenting}
                  label={tn.lang.coparenting}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 2 ? -1 : 2,
                      -1
                    )
                  }
                />
                <Progress
                  progress={this.state.children}
                  label={tn.lang.children}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 3 ? -1 : 3,
                      -1
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div
            className="muni-toppanel muni-fixed"
            style={
              this.state.showFixedHeader
                ? {}
                : { opacity: 0, pointerEvents: "none" }
            }
          >
            <div className="muni-topgroup-minwidth">
              <div className="muni-topgroup-progressbar">
                <Progress
                  progress={this.state.me}
                  label={tn.lang.aboutMe}
                  selected={this.state.selectedCategory === 1}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 1 ? -1 : 1,
                      -1
                    )
                  }
                />
                <Progress
                  progress={this.state.coparenting}
                  label={tn.lang.coparenting}
                  selected={this.state.selectedCategory === 2}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 2 ? -1 : 2,
                      -1
                    )
                  }
                />
                <Progress
                  progress={this.state.children}
                  label={tn.lang.children}
                  selected={this.state.selectedCategory === 3}
                  selectCategory={() =>
                    this.selectCategory(
                      this.state.selectedCategory === 3 ? -1 : 3,
                      -1
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="muni-category-mobile">
            <div className="muni-categorygroup" style={{ marginTop: "30px" }}>
              <MainCategory
                category={tn.lang.aboutMe}
                imageURL={
                  1 === this.state.selectedCategory
                    ? require("../images/self-love-color.png")
                    : require("../images/self-love.png")
                }
                selected={1 === this.state.selectedCategory}
                selectCategory={() => this.selectCategory(1, -1)}
              />
              <MainCategory
                category={tn.lang.coparenting}
                imageURL={
                  2 === this.state.selectedCategory
                    ? require("../images/protect-color.png")
                    : require("../images/protect.png")
                }
                selected={2 === this.state.selectedCategory}
                selectCategory={() => this.selectCategory(2, -1)}
              />
              <MainCategory
                category={tn.lang.children}
                imageURL={
                  3 === this.state.selectedCategory
                    ? require("../images/children-color.png")
                    : require("../images/children.png")
                }
                selected={3 === this.state.selectedCategory}
                selectCategory={() => this.selectCategory(3, -1)}
              />
            </div>

            {this.state.selectedCategory !== -1 && (
              <div
                className="muni-categorygroup"
                style={{ marginBottom: "30px" }}
              >
                <select
                  value={this.state.selectedSubCategory}
                  onChange={(e) =>
                    this.selectCategory(
                      this.state.selectedCategory,
                      parseInt(e.target.value)
                    )
                  }
                >
                  <option value="-1" disabled>
                    Välj en underkategori
                  </option>
                  {this.state.filteredCategories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {articlesToShow.map((article) => (
              <Link
                key={article.id}
                to={`/panel/article/${article.slug}`}
                style={{ textDecoration: "none" }}
              >
                <ArticleCard
                  title={article.title}
                  text={article.excerpt}
                  src={article.imageUrl}
                  slug={article.slug}
                  read={this.context.readArticles.indexOf(article.id) > -1}
                />
              </Link>
            ))}
          </Masonry>
          {filteredArticles.length > 6 && (
            <button onClick={this.handleShowMore} className="show-more-button">
              {isShowingAll ? tn.lang.showLess : tn.lang.showMore}
            </button>
          )}
        </div>
      </>
    )
  }

  private onScroll(e: any) {
    if (!this.state.showFixedHeader && e.target.scrollTop > 320) {
      this.setState({ showFixedHeader: true })
    } else if (this.state.showFixedHeader && e.target.scrollTop < 320) {
      this.setState({ showFixedHeader: false })
    }
  }

  private articleRead(articleId: number | undefined) {
    const context: IContext = this.context
    if (
      articleId &&
      articleId > 1 &&
      context.readArticles.indexOf(articleId) === -1
    ) {
      context.readArticle(articleId)
    }
  }

  private exerciseCompleted(slug: string, response: string) {
    const context: IContext = this.context
    context.completedExercise({ slug, response })
  }

  private reCalculate() {
    const context: IContext = this.context
    let me: number = 0
    let coparenting: number = 0
    let children: number = 0
    for (const articleId of context.readArticles) {
      const foundArticle = this.state.articles.find(
        (article) => article.id === articleId
      )
      if (foundArticle) {
        me += this.validateGrade(foundArticle.pointsMe)
        coparenting += this.validateGrade(foundArticle.pointsCoparenting)
        children += this.validateGrade(foundArticle.pointsChild)
      }
    }
    for (const exer of context.completedExercises) {
      const foundExercise = exercies.find((exe) => exe.slug === exer.slug)
      if (foundExercise) {
        me += this.validateGrade(foundExercise.pointsMe)
        coparenting += this.validateGrade(foundExercise.pointsCoparenting)
        children += this.validateGrade(foundExercise.pointsChild)
      }
    }
    this.setState({ me, coparenting, children })
  }

  private validateGrade(grade: number): number {
    if (
      typeof grade === "number" &&
      isFinite(grade) &&
      grade >= 1 &&
      grade <= 100
    ) {
      return grade
    }
    return 0
  }
}

PanelPage.contextType = LoginContext
export default PanelPage
