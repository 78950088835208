import React from "react"
import { useLoginContext } from "./useLoginContext"
import Translate from "service/Translate"
import UserInfoLoggedIn from "./UserInfoLoggedIn"
import { Link } from "react-router-dom"

const PanelPageLogoName = () => {
  const tn: Translate = Translate.getInstance()
  const context = useLoginContext()
  return (
    <div className="muni-topgroup">
      <div className="muni-logo-group">
        <img
          className="muni-logo"
          src="https://www.varannanvecka.app/wp-content/uploads/2021/03/VV-symbol.svg"
          alt="Varannan Vecka"
        />
        <div>
          <span className="muni-vvname">
            Varannan
            <br />
            Vecka
          </span>
        </div>
      </div>
      <div>
        {context.completedExercises.length > 0 ? (
          <Link className="muni-grouptitle" to={"/panel/reflections"}>
            {tn.lang.yourReflections}
          </Link>
        ) : null}
      </div>
      <UserInfoLoggedIn />
    </div>
  )
}
export default PanelPageLogoName
