const baselanguage = {
  // General
  ok: "OK",
  logout: "Logga ut",
  activity: "Din aktivitet",
  read: "Läst",
  completedcat: "Genomförda moment",
  aboutMe: "Om mig",
  coparenting: "Coparenting",
  children: "Barnet",
  readMore: "läs mer",

  vvIntroTextExample: "Förslag på text här",

  vvintroMalinHeadline: "Ett verktyg byggt på svensk barnpsykologisk forskning",

  vvintroTeamText:"Vårt team består av både forskare och yrkesverksamma som är specialiserade inom familjeuppbrott och barns behov under och efter separationer.",

  vvintroWelcomeAppName:
    "Separationsguiden har utvecklats för ditt eget och barnens bästa under och efter separation.",
  
  vvintroWelcomeAppName2: "Vi vet att när föräldrarna mår okej gör ofta barnen det också.",

  vvintroAppName: "Varannan vecka",

  vvintroMalinText:
    "Barnpsykologen och docenten Malin Bergström är en av våra medgrundare. Hennes forskning  om barns behov och bästa under och efter separation är världsledande. Verktygen i Varannan Vecka bygger på hennes forskning.",

  vvintroReflection:
    "Här finns information och stöd för att få ihop livspusslet och säkerställa ditt eget och barnens bästa efter separationen.",

  vvintro:
    "I Varannan Vecka hittar du stöd för att få ihop livspusslet och säkerställa barnets bästa efter separationen.",

  vvintro2:
    "Separationsguiden är utvecklad med Malin Bergström som är Sveriges ledande forskare och barnpsykolog med inrikting på separationer.",

  vvexplain: "Så här fungerar Separationsguiden",
  vvexplain1: "Skapa ett konto här nedan.",
  vvexplain2:
    "Läs artiklar och gör övningar för att få kunskap och praktiska tips för att barnet och du skall må så bra som möjligt.",
  vvexplain3:
    "Om du vill, ladda ner Varannan Vecka appen. I appen finns verktyg för samarbete:",
  vvexplain3sublist1: "Scheman",
  vvexplain3sublist2: "Packlista",
  vvexplain3sublist3: "Kalender",
  vvexplain3sublist4: "Arbetsfördelning",
  vvexplain3sublist5: "Chat",
  vvexplain3sublist6: "Ekonomi kring barnet (under utveckling)",
  vvexplain3sublist7: "Delad information (under utveckling)",

  goapp: "Länk till samarbetsappen",

  signupGotAccount: "Jag har redan ett konto",
  signupGotCode: "Jag har en kod från min kommun",
  signupNew: "Jag vill skapa ett nytt konto",

  logIn: "Logga in",
  forgottenPassword: "Glömt lösenord?",
  getStarted: "Kom igång",
  back: "Tillbaka",
  usePW: "Använd epost/lösenord",
  useCode: "Anonym inloggning med kod",

  alreadyAcc: "Redan konto?",
  noAcc: "Saknar du konto?",
  iWantAnAcc: "Jag vill skapa ett konto.",
  createAcc: "Skapa konto",
  register: "Registrera",
  visitApp: "Besök appen",
  visitAppSite: "Gå till Varannan vecka",


  firstName: "Förnamn",
  lastName: "Efternamn",
  email: "E-post",
  password: "Lösenord",
  iWantAnon: "Jag vill vara helt anonym",
  codeFromMun: "Kod från din kommun",
  iApprove: "Jag godkänner  ",
  terms: "användarvillkoren",
  sureAnon:
    "Om du skapar ett konto där du inte anger din epost kan du inte senare logga in i appen för samarbete med din medförälder. Är du säker?",
  pickMuni: "Välj den kommun du är folkbokförd i:",
  yourAnswer: "Ditt svar...",

  forgotPassword: "Begär en länk för att sätta nytt lösenord",
  submit: "Skicka",
  save: "Spara",
  cancel: "Avbryt",

  resetPwFail: "Det fanns ingen epost som matchade den du angav",
  resetPwSuccess: "Kolla din epost!",

  loginFail: "Epost / Lösenord verkade inte stämma",
  invalidEmail: "Ogiltig e-postadress",
  loginCodeFail: "Koden du angav verkar inte stämma",
  loginCodeUsed: "Koden du angav är redan använd",

  loadFail: "Kunde inte ladda data. Försök igen lite senare!",
  createFail: "Kunde inte skapa användare: ",

  exercise: "Övning",

  yourReflections: "Dina svar",

  // how it works
  howItWorksSubHeaders1: "Tre olika delmoment",
  howItWorksSubHeaders2: " sätter fokus i relationen till barnen, dig själv och bidrar till sunda val i kontakten med medföräldern.",

  howItWorksSubHeaders3: "Följ din utveckling",
  howItWorksSubHeaders4: " med Separationsguiden. Verktyget ger dig en tydlig överblick över genomförda moment och dina framsteg.",


  childrenFirstArticle: "Barns behov hamnar ofta i skymundan i en separation.",

  childrenFirstText:
    " Här finns artiklar och övningar för att få koll på barnens situation under en separation. Så du kan ge rätt stöd som förälder.",

  coparentingExplainArticle:
    "När vi känner oss okej själva är det lättare att samarbeta med andra om barnen.",
  coparentingExplainText:
    " Här finns artiklar om föräldraskap - gemensamt eller i parallella spår. ",

  aboutMeExplainArticle:
    "Det är viktigt att sätta syrgasmasken på sig själv innan man kan göra bra saker med andra.",
  aboutMeExplainText: " Här finns artiklar & övningar för - och om - dig",

  articleAmountChildren: "artiklar om barn under och efter separation.",
  articleAmountCoparenting: " artiklar om samarbete och co-parenting.",
  articleAmountAboutMe: "artiklar & övningar för - och om - dig.",

  experts: "Möt våra experter",

  expertsText1: "Separationsguiden är byggt på lång erfarenhet av familjeuppbrott och världsledande forskning om barns behov och bästa under och efter separation.",
  expertsText2: "Vårt team består av både forskare och experter som jobbar hårt och passionerat för att förenkla livet för särlevande föräldrar och barn.",

  expertsButton: "Läs mer om vårt team",


  expertTitleElisabeth: "Jurist och medlare",
  expertTitleMalin: "Barnpsykolog, docent och författare ",
  expertTitleLinda: "Jurist och författare",

  expertTextElisabeth:
    "Elisabeth Scholander har lång erfarenhet som medlare och jurist, specialiserad på konfliktlösning för familjer gällande vårdnad, boende och umgänge. Hon är en av våra medgrundare och experter.",
  expertTextMalin:
    "Malin Bergströms forskning om barns behov och bästa under och efter separation är världsledande. Hon är en av våra medgrundare och verktygen vi tar fram bygger på hennes forskning.",
  expertTextLinda:
    "Linda Ljunggren Syding är en av våra medgrundare, hon är författare till boken ”Skiljas med hjärta och hjärna” och är familjerättsjurist. Linda har lång erfarenhet av familjeuppbrott och rådgivning med barnens bästa i fokus.",


  helpedFamilies: "Vi har hjälpt tusentals familjer",

  youtubeHowVarannanVeckaWorks: "Varannan vecka på 30 sekunder",

  youtubeExplain: "Ta nästa steg med Varannan Vecka-appen",
  youtubeText:
    "I Varannan Vecka-appen finns allt ni behöver för ett smartare och enklare samarbete när barnen bor under två tak.",

  upsText1: "Allt om barnen på samma plats.",  
  upsText2: "Låt appen föra dialogen, påminna och informera.",  
  upsText3: "Packlistor och annan bytesplanering som hjälper dig att få järnkoll.",  
  upsText4: "Sköt den gemensamma ekonomin direkt i appen",
    
  showLess: "Visa mindre",
  showMore: "Visa mer",

  
  /** Separationsguiden for work */

  reflektionForWorkHeadline: "Möter du separerade föräldrar i ditt arbete?",

  reflektionForWorktext:
    "För verksamma inom kommun, hälso- och sjukvård och andra organisationer innebär Separationsguiden unika möjligheter att nå fler föräldrar med en större mängd kunskap och verktyg för bearbetning.",
  reflektionForWorktextTwo: "Det är lätt att komma igång och helt gratis.",
  reflektionContactUs: "Kontakta oss",
  reflektionSupport: "Support",

  /* Hamburger Menu */

  hamburgerHowItWorks: "Så fungerar Separationsguiden",
  hamburgerHowItWorksHeadline: "Stöd under seperation",
  hamburgerHowItWorksPrivatePerson: "Privatperson",
  hamburgerHowItWorksPrivatePersonText: "Använd Separationsguiden som privatperson",
  hamburgerHowItWorksProfessional: "Professionell",
  hamburgerHowItWorksProfessionalText: "Använd Separationsguiden i ditt arbete",

  hamburgerNextStep: "Ta nästa steg",
  hamburgerExploreArticles: "UTFORSKA ARTIKLARNA",

  hamburgerTheChildren: "Barnet",
  hamburgerTheChildrenText: "Barnens situation under seperation",

  hamburgerCoParenting: "Co-parenting",
  hamburgerCoParentingText: "Föräldrarskap och samarbete",

  hamburgerAboutMe: "Om mig",
  hamburgerAboutMeText: "Verktyg för - och om - dig!",

  hamburgerExercise: "Övningar",
  hamburgerExploreExercise: "Utforksa övningarna",

  hamburgerExerciseWithChildren: "Övningar med barnet i fokus",
  hamburgerExerciseWithChildrenText: "Refletera och lär om barnet",

  hamburgerExerciseCoParenting: "Övningar med barnet i fokus",
  hamburgerExerciseCoParentingText: "Föräldrarskap och samarbete",

  hamburgerExerciseMe: "Övningar med fokus på dig",
  hamburgerExerciseMeText: "Övningar för - och om - dig!",

  hamburgerAboutUs: "Om oss",

  hamburgerGetToKnowUs: "LÄR KÄNNA OSS",

  hamburgerAboutUsHeadline: "Teamet Varannan Vecka",
  hamburgerAboutUsHeadlineText: "Ett team för dig under och efter seperation",

  hamburgerAppName: "Ett hjälpmedel efter separation",
  hamburgerLifePuzzle: "Varannan vecka-appen",
  hamburgerLifePuzzleText: "En app för varannanvecka livet",
  hamburgerGoToApp: "Ladda ner appen >",


  professionelButton: "Professionell",

  /* FOOTER */

  footerAboutUs:
    "Vårt team jobbar hårt och passionerat för att förenkla livet för särlevande förälder och barn. Lär känna oss bättre och häng med på vår spännande företagsresa!",
}

const engelska: typeof baselanguage = {
  // General
  ok: "OK",
  logout: "Log out",
  activity: "Your activity",
  read: "Read",
  completedcat: "Progress",
  aboutMe: "About Me",
  coparenting: "Coparenting",
  children: "The Child",

  readMore: "read more",
  vvIntroTextExample: "Suggestions for text here",

  vvintroWelcomeAppName:
    "The tool Separation Guide has been developed for your own and the children's well-being during and after separation.",

  vvintroWelcomeAppName2:
    "We know that when the parents are doing well, the children often do too.",

  vvintroAppName: "Varannan vecka",

  vvintroMalinHeadline: "A tool built on Swedish child psychological research.",

  vvintroTeamText:"Our team consists of both researchers and professionals specialized in family disruptions and children's needs during and after separations.",

  vvintroMalinText:
    "Child psychologist and associate professor Malin Bergström is one of our co-founders. Her research on children's needs and well-being during and after separation is world-leading. The tools in Every Other Week are based on her research.",

  vvintro:
    "In Varannan Vecka you will find support for the everyday struggles and ensuring the well beeing for your children after the separation.",

  vvintroReflection:
    "Here is information and support to help you put together the puzzle of life and ensure the best for yourself and the children after the separation.",

  vvintro2:
    "The app is developed with Malin Bergström who is Sweden's leading researcher and child psychologist specializing in separations.",

  vvexplain: "This is how the Separation Guide works:",
  vvexplain1: "Create an account here below.",
  vvexplain2:
    "Read articles and do exercises to gain knowledge and practical tips so that you and your child feel as good as possible.",
  vvexplain3:
    "If you want, download the Varannan Vecka app. In the app, there are tools for collaboration:",
  vvexplain3sublist1: "Schedules",
  vvexplain3sublist2: "Packing list",
  vvexplain3sublist3: "Shared Calendar",
  vvexplain3sublist4: "Work distribution",
  vvexplain3sublist5: "Chat",
  vvexplain3sublist6: "Economical collaboration (under development)",
  vvexplain3sublist7: "Shared information (under development)",

  goapp: "Link to the coparenting app",

  signupGotAccount: "I already have an account",
  signupGotCode: "I have a code from my municipality",
  signupNew: "I want to create a new account",

  logIn: "Log in",
  getStarted: "Get Started",
  forgottenPassword: "Forgotten Password?",

  alreadyAcc: "Got an account?",
  noAcc: "No account?",

  back: "Back",
  usePW: "use email/password",
  useCode: "Log in anonymously using code",

  iWantAnAcc: "Create a new account.",
  createAcc: "Create Account",
  register: "Register",
  firstName: "First name",
  lastName: "Surname ",
  email: "E-mail",
  password: "Password",
  iWantAnon: "I want complete anonymity",
  codeFromMun: "Code from your municipality",
  iApprove: "I approve of the ",
  terms: "terms and conditions",

  sureAnon:
    "If you create an account where you do not enter your email, you will not be able to log in to the app later for collaboration with your coparent. Are you sure?",

  pickMuni: "Select the municipality where you are registered:",
  yourAnswer: "Your answer...",

  forgotPassword: "Request a link to reset password",
  submit: "Submit",
  save: "Save",
  cancel: "Cancel",

  visitApp: "Visit App",
  visitAppSite: "Visit the Varannan vecka site",


  resetPwFail: "There is no such email registered",
  resetPwSuccess: "Check your email!",
  invalidEmail: "Invalid email",

  loginFail: "Email / Password did not seem to match...",
  loginCodeFail: "We could not find any such code",
  loginCodeUsed: "The code is already used",

  loadFail: "Could not load data. Please try again a little later!",
  createFail: "Could not create user: ",

  exercise: "Exercise",

  yourReflections: "Your answers",

  howItWorksSubHeaders1: "Three different aspects",
  howItWorksSubHeaders2: " focus on the relationship with the children, yourself, and contribute to healthy choices in contact with the co-parent.",

  howItWorksSubHeaders3: "Follow your development",
  howItWorksSubHeaders4: " with the Separation Guide. The tool provides you with a clear overview of completed tasks and your progress.",


  childrenFirstArticle:
    "Children's needs often end up overshadowed in a separation.",

  childrenFirstText:
    " Here you'll find articles and exercises to understand the children's situation, so you can provide the right support as a parent.",

  coparentingExplainArticle:
    "When we feel okay ourselves, it's easier to collaborate with others regarding the children.",
  coparentingExplainText:
    " Here you will find articles on parenting - together or on parallel tracks.",

  aboutMeExplainArticle:
    "It's important to put on your own oxygen mask before you can do well with others.",
  aboutMeExplainText:
    " Here you'll find articles & exercises for - and about - you.",

  articleAmountChildren: "articles about children",

  articleAmountCoparenting: "articles about coparenting",

  articleAmountAboutMe: "articles about me",

  youtubeExplain: "Take the next step: Varannan Vecka App",
  youtubeText:
    "In the Varannan Vecka app, you'll find everything you need for smarter and easier collaboration when children live under two roofs.",

  upsText1: "Everything about the children in one place.",  
  upsText2: "Let the app facilitate, remind, and inform.",  
  upsText3: "Packing lists and other exchange planning to help you stay organized.",  
  upsText4: "Manage the shared finances directly in the app.",  

  youtubeHowVarannanVeckaWorks: "How Varannan Vecka works",

  experts: "Meet our experts",

  expertsText1: "The tool Separation Guide is built on extensive experience of family disruptions and world-leading research on children's needs and well-being during and after separations.",
  expertsText2: "Our team consists of both researchers and experts who work hard and passionately to simplify life for single parents and children.",

  expertsButton: "Read more about our team",


  expertTitleElisabeth: "Lawyer and mediator",
  expertTitleMalin: "Child psychologist, docent and author",
  expertTitleLinda: "Lawyer and author",

  expertTextElisabeth:
    "Elisabeth Scholander has extensive experience as a mediator and lawyer, specializing in conflict resolution for families regarding custody, residence, and visitation. She is one of our co-founders and experts.",
  expertTextMalin:
    "Malin Bergström's research on children's needs and well-being during and after separation is world-leading. She is one of our co-founders, and the tools we develop are based on her research.",
  expertTextLinda:
    "Linda Ljunggren Syding is one of our co-founder, she is the author of the book ”Skiljas med hjärta och hjärna” and is a family law attorney.  Linda has extensive experience in family disruptions and counseling with a focus on the best interests of the children.",

  //  expertQuoteElisabeth2:
  //   "In Separationsguiden we have gathered knowledge and various perspectives for you as a parent or important adult. Because we know that when parents are doing well, children often are too",

    
  // expertQuoteMalin:
  //   "For children, it's like soothing balm for the soul to know that their parents can communicate with each other and that they are doing reasonably well, even if it takes some time to adjust after a separation. Because it often takes time, even for the children.",
  // expertQuoteLinda:
  //   "We are pleased to offer all parents a valuable digital parenting support during separation at no cost, regardless of the municipality in which they reside with their children!",

  helpedFamilies: "We have helped thousands of families",

  showLess: "Show less",
  showMore: "Show more",

  /** Reflektion for work */

  reflektionForWorkHeadline: "Do you encounter separated parents in your work?",

  reflektionForWorktext:
    "For those working in municipalities, health care, and other organizations, the Separation Guide tool offers unique opportunities to reach more parents with a greater amount of knowledge and tools for processing.",
  reflektionForWorktextTwo: "It's easy to get started and completely free!",

  reflektionContactUs: "Contact us",
  reflektionSupport: "Support",


  /* Hamburger Menu */

  hamburgerHowItWorks: "How It Works",
  hamburgerHowItWorksHeadline: "SUPPORT DURING SEPARATION",
  hamburgerHowItWorksPrivatePerson: "Private Person",
  hamburgerHowItWorksPrivatePersonText: "Use the Separation Guide as a private person",
  hamburgerHowItWorksProfessional: "Professional",
  hamburgerHowItWorksProfessionalText: "Use the Separation Guide in your work",

  hamburgerNextStep: "Next step: Varannan Vecka",
  hamburgerExploreArticles: "EXPLORE THE ARTICLES",

  hamburgerTheChildren: "The Child",
  hamburgerTheChildrenText: "The situation of children during separation",

  hamburgerCoParenting: "Co-parenting",
  hamburgerCoParentingText: "Parenting and cooperation",

  hamburgerAboutMe: "About Me",
  hamburgerAboutMeText: "Tools for - and about - you!",

  hamburgerExercise: "Exercises",
  hamburgerExploreExercise: "Explore the Exercises",

  hamburgerExerciseWithChildren: "Exercises with a Focus on the Child",
  hamburgerExerciseWithChildrenText: "Reflect and learn about the child",

  hamburgerExerciseCoParenting: "Exercises in Co-parenting",
  hamburgerExerciseCoParentingText: "Parenting and cooperation",

  hamburgerExerciseMe: "Exercises with a Focus on You",
  hamburgerExerciseMeText: "Exercises for - and about - you!",

  hamburgerAboutUs: "About Us",
  hamburgerGetToKnowUs: "GET TO KNOW US",
  hamburgerAboutUsHeadline: "Team Varannan Vecka",
  hamburgerAboutUsHeadlineText: "A team for you during and after separation",

  hamburgerAppName: "Support after separation",
  hamburgerLifePuzzle: "The app Varannan vecka",
  hamburgerLifePuzzleText: "An app for a every other week life",
  hamburgerGoToApp: "Download to the app >",


  professionelButton: "Professional",

  /* FOOTER */

  footerAboutUs:
    "Our team works hard and passionately to simplify the lives of separated parents and children. Get to know us better and join us on our exciting company journey!",
}

export default class Translate {
  public static getInstance(): Translate {
    if (!Translate.instance) {
      Translate.instance = new Translate()
    }

    return Translate.instance
  }

  private static instance: Translate
  public lang: typeof baselanguage
  private chosenLanguage: string

  private constructor() {
    this.chosenLanguage = "SE"
    this.lang = baselanguage
  }

  public setLanguage(language: "SE" | "EN"): void {
    if (language === "SE") {
      this.chosenLanguage = "SE"
      this.lang = baselanguage
    } else if (language === "EN") {
      this.chosenLanguage = "EN"
      this.lang = engelska
    }
  }

  public getLanguage(): string {
    return this.chosenLanguage
  }
}
